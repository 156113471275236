@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');

* {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -.025rem;
  // box-shadow: 0 0 1px 0 red;
}

.full-width {
  width: 1200px;
}

.content-width {
  width: 1180px;
}

.nanumsquare {
  font-family: 'NanumSquareRound',sans-serif;
}

aside.top {
  width: 100%;
  background: #eaeaea;
  .container {
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    text-align: right;
    a {
      padding-left: 20px;
      font-size: 12px;
      &:hover {
        color: #097ebd;
      }
    }
  }
} // .top

nav.main {
  margin-top: 20px;
  .container {
    margin: 0 auto;
    position: relative;
    $col_height: 80px;
    .col {
      float: left;
      height: $col_height;
      line-height: $col_height;
    }
    
    .col.logo {
      width: 30%;
      img {
        width: 220px;
        vertical-align: middle;
      }
    }
    
    .col.menus {
      width: 70%;
      text-align: right;
      a {
        color: #555555;
        font-weight: 500;
        font-size: 18px;
        box-sizing: border-box;
        &:hover {
          color: #097ebd;
        }
      }
    }
  } // .container
} // nav.main

nav.main .dropdown {

}

nav.main .dropdown .dropbtn {
  cursor: pointer;
  color: #333;
  background: #fff;
}

nav.main .dropdown .dropdown-content {
  height: 80px;
}

nav.main .dropdown .dropdown-content ul {
  height: 100%;
}

nav.main .dropdown .dropdown-content ul li {
  height: 100%;
  line-height: initial;
}

nav.main .dropdown .dropdown-content ul li a {
  line-height: initial;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav.main .dropdown .dropdown-content ul li a:hover {
  background: #009aec;
}

nav.main .dropdown ul li {
  text-align: center;
}

nav.main .dropdown.intro ul li {
  float: left;
  width: 50%;
}

nav.main .dropdown.kspia ul li {
  float: left;
  width: 33.3333%;
}

nav.main .dropdown.eduProgram ul li {
  float: left;
  width: 50%;
}

nav.main .dropdown.customerCenter ul li {
  float: left;
  width: 25%;
}

nav.main .dropdown ul li a { 
  background-color: #008ad5;
  color: #fff !important;
}

nav.mobile {
  display: none;
  text-align: center;
}

nav.mobile .logo {
  width: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
}

nav.mobile .accordion.active,
nav.mobile .accordion:hover {
  background-color: #009aec;
}

nav.mobile .accordion:after {
  color: #fff;
}

nav.mobile button {
  background-color: #008ad5;
  color: #fff;
}

nav.mobile .panel a {
  display: block;
  text-align: left;
  padding: 18px 0;
}


footer {
  margin-top: 20px;
  position: relative;
  .container {
    margin-left: auto;
    margin-right: auto;
    $logo_width: 80px;
    .col {
      float: left;
      padding: 10px;
      box-sizing: border-box;
      &.logo {
        width: $logo_width + 20px;
        img {
          width: $logo_width;
        }
      }
      
      &.detail {
        width: calc(100% - #{$logo_width + 20px});
        padding-left: 40px;
        color: #a0a0a0;
        position: relative;
        p {
          font-weight: 200;
          margin-bottom: 9px;
          font-size: 14px;
          line-height: 1.4;
          &.copyright {
            margin-top: 20px;
          }
          strong {
            font-weight: 400;
          }
        }
      }
    }
  }
  
  .wrapper-playAndPause {
    left: calc(50% - (500px + 150px));
    display: inline-block;
    bottom: 40px;
    position: absolute;
    
    .wrapper-image {
      border: 2px solid #e8e8e8;
      padding: 3px 8px;
      float: left;
      &:last-child {
        border-left: 0;
      }
      img {
        width: 7px;
        vertical-align: middle;
        cursor: pointer;
        &.swiper-button-next {
          background-image: none !important;
          position: relative !important;
          left: initial !important;
          top: initial !important;
          right: initial !important;
          height: auto !important;
          margin-top: initial !important;
          margin-top: 0;
          right: 0 !important;
        }
      }
    }
    
  }
  
  .container.slide {
    height: 100px;
    .swiper-container {
      height: 100%;
      .swiper-wrapper {
        height: 100%;
        .swiper-slide {
          height: 100%;
          line-height: 100px;
          text-align: center;
          img {
            max-height: 60px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 3;
  left: 0;
  margin-top: -2px;
  background: #fff;
  
  ul {
    list-style-type: none;
    width: 100%;
    box-sizing: border-box;
  }
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  padding-left: 0;
  width: 100%;
}

.dropdown-content a:hover {
}

.dropdown:hover {
  background: #008ad5;
}

.dropdown:hover .dropbtn {
  background-color: #008ad5;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: #fff !important;
  background-color: #008ad5 !important;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.accordion:after {
  content: '\002B';
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.accordion.active:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

@media only screen and (max-width: 760px) {
  nav.main {
    display: none;
  }

  nav.mobile {
    display: block;
  }
}

@media only screen and (max-width: 440px) {

}
